import React from 'react'
import LBBirdText from '../../../../../../assets/images/icons/LB-bird-text.png'

function index () {
  return (
    <div className='fixed-top main-nav'>
      <nav className='navbar navbar-expand-xl'>
        <div className='container-fluid justify-content-center'>
          <a className='navbar-brand mr-0 bg-transparent' href='/'>
            <img src={LBBirdText} style={{height: '42px !important'}} />
          </a>
        </div>
      </nav>
    </div>
  )
}

export default index
