import React, { useEffect, useRef, useState } from 'react'
import { useSelector, Provider, useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import $ from 'jquery'
import * as Sentry from '@sentry/browser'

import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from 'styled-components'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import { mainTheme } from '../../themes'
import { CookiesProvider, useCookies } from 'react-cookie'
import smoothscroll from 'smoothscroll-polyfill'

import axios from 'axios'
import { Service } from 'axios-middleware'
import SetCbaCookie from '../../helpers/SetCbaCookie'
import CheckCbaExclusiveItem from '../../helpers/CheckCbaExclusiveItem'
import AxiosNotifcationMiddleware from '../../middlewares/AxiosNotifcationMiddleware'
import AxiosResourceMiddleware from '../../middlewares/AxiosResourceMiddleware'
import AxiosRequestMiddleware from '../../middlewares/AxiosRequestMiddleware'

import { MobileActionBar } from '../../components/ActionBar'
import BackToTopButton from './../../components/Buttons/BackToTopButton'
import ScrollToTop from '../../components/utils/ScrollToTop'
import LastLocationCookie from './components/LastLocationCookie'
import configureStore, { history } from './store'
import RootSaga from './sagas'
import { getBaseUrl, getIsMobile, getCurrentUser, getRouter, getAllExperiments, isLoggedIn, getUserEmail } from './reducers'

import Main from './components/Main'
import MainNavbar from './components/MainNavbar'
import BasicNavbar from './components/MainNavbar/BasicNavbar'
import CloseNavBar from './components/MainNavbar/CloseNavBar'
import CloseHomePage from './CloseHomePage'
import Footer from './components/Footer'
import Notifications from './components/Notifications'
import FavouritesModal from './components/Modals/FavouritesModal'
import { currentUserUpdate, isCurrentUserLoading, userAffinityRequestStart } from './actions/users'
import { gon, GLEAP_KEY, LB_NATIVE_APP_VERSION, LB_APP_TAB_BAR_VERSION } from '../../../constant'
import ErrorBoundries from '../../components/ErrorBoundry/index'
import calculateActiveVariant from './components/MVT/calculateActiveVariant'
import { activeExperiments } from './components/MVT/activeExperiments'
import WelcomePage from './components/pages/NarrativePage'
import PersonalisedPage from './components/pages/Personalised'
import { setModalClose, showUserSlider } from './actions'
import doLogout from '../../components/utils/DoLogout'
import { beActiveClickEvents, storeLinkClick } from '../../components/dataLayers/extensionDataLayer'
import { Modal } from 'react-bootstrap'
import { LbRedirect } from '../../components/Icons'
import useCdnConnect from './hooks/cdnConnect'
// import { getLoggedInUser } from "./requesters/UsersRequester"

import Gleap from 'gleap'

const startBoardApp = rootElem => {
  const store = configureStore()

  store.runSaga(RootSaga)

  // Configure Axios middlewares
  const service = new Service(axios)
  service.register([
    new AxiosRequestMiddleware(),
    new AxiosNotifcationMiddleware(),
    new AxiosResourceMiddleware(store)
  ])
  return (
    <ErrorBoundries>
      <CookiesProvider>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={mainTheme}>
              <MainBody />
            </ThemeProvider>
          </ConnectedRouter>
        </Provider>
      </CookiesProvider>
    </ErrorBoundries>

  )
}

// attempted to lock body scroll when mobile slider menu open
function MainBody () {
  smoothscroll.polyfill()
  const bodyRef = useRef(null)
  const [cookies, setCookie] = useCookies([])
  const { showUserMenu, showCatMenu } = useSelector(state => state.menu)
  const isMobile = useSelector(getIsMobile)
  const allExperiments = useSelector(getAllExperiments) || []
  const currentUser = useSelector(getCurrentUser)
  const userId = useSelector(getUserEmail)
  const { location } = useSelector(getRouter)
  const isUserLoggedIn = useSelector(isLoggedIn)
  const path = location?.pathname
  const homePage = (location.pathname === '/' || location.pathname === '')
  const dispatch = useDispatch()
  const apiBaseUrl = useSelector(getBaseUrl)
  const history = useHistory()
  const [mounted, setMounted] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const isExtensionRedirect = localStorage.getItem('extensionRedirect')
  const queryParamObj = new URLSearchParams(window.location.search)
  const queryString = queryParamObj?.get('utm_source')
  const wtidParam = queryParamObj?.get('wtid') || null
  const welcomeParam = queryParamObj?.get('welcome') || null
  const hideNavBar = !!window.ReactNativeWebView && homePage && LB_NATIVE_APP_VERSION >= LB_APP_TAB_BAR_VERSION

  const [nidGenerated, setNidGenerated] = useState(false)

  useCdnConnect()

  const isHomePageRedirect = homePage && !window.ReactNativeWebView && queryString?.toLocaleLowerCase() !== 'cba' && !wtidParam && welcomeParam !== 'false'

  if (!mounted) {
    const lowerQueryParam = _.transform(location?.query || {}, function (result, val, key) {
      result[key.toLowerCase()] = val
    })

    //Get the WTID either from URL, user attribute or the cookie
    const wtid = gon?.current_user?.wtid || cookies.wtid || lowerQueryParam.wtid
    const wtidValid = wtid && wtid.length == 40
    //If there is a WTID, check its valid and set the CBA cookie
    wtid && wtidValid && SetCbaCookie(wtid, setCookie)
  }

  const setNid = () => {
    let nid = localStorage.getItem('lbnid')
    let newnid = localStorage.getItem('lbnidnew')

    let sentryID = null
    if(typeof nid == 'string' && nid.split('-').length < 3){
      sentryID = Sentry.captureException({ invalidNid: nid, location: window.location, appVersion: LB_NATIVE_APP_VERSION, newNid: newnid }, 'Invalid nid captured')
    }

    if (nid == 'undefined') {
      let lbwebnid = localStorage.getItem('lbwebnid')
      if(!lbwebnid) {
        lbwebnid = crypto.randomUUID()
        localStorage.setItem('lbwebnid', lbwebnid)
      }
      localStorage.setItem('lbnid', lbwebnid)
      nid = lbwebnid

      // capture error in Sentry for undefined nid from app to webview
      let undefinedNidErrorObj = {
        newWebNid: nid,
        appVersion: LB_NATIVE_APP_VERSION,
        location: window.location,
        sentryID
      }

      // If referrer link captured for invalid nid in Snowplow database (don't compare SENTRY ERROS) is different than homepage,
      // it means setNid is not getting called on those links, need to debug why?
      Sentry.captureException(undefinedNidErrorObj, 'undefined nid received from app in webview')

    } else if (!nid) {
      nid = crypto.randomUUID()
      localStorage.setItem('lbnid', nid)
    }
    const uid = 'nid-' + nid
    if (currentUser) window.snowplow && window.snowplow('setUserId', uid + '_' + currentUser?.id)
    else window.snowplow && window.snowplow('setUserId', uid)

    setNidGenerated(true)
    return uid
  }

  useEffect(() => {
    if (location.pathname.includes('/top-vouchers')) {
      history.replace(location.pathname.replace('/top-vouchers', '/top-promo-codes'))
    } else if (location.pathname.includes('/top-coupons')) {
      history.replace(location.pathname.replace('/top-coupons', '/top-promo-codes'))
    }

    const uid = setNid()
    window.dataLayer?.push({
      appId: process.env.REACT_APP_GTM_APP_ID,
      userId: currentUser?.id || uid,
      platform: window.ReactNativeWebView ? 'ReactNativeWebView' : ''
    })

    !sessionStorage.getItem('user_affinities') && cookies?.sp && isUserLoggedIn &&
      dispatch(userAffinityRequestStart({ user_id: currentUser?.id, network_userid: cookies?.sp }))
    if (gon.auth_token) {
      dispatch(isCurrentUserLoading(true))
      axios.get(`${gon.urls.api}/users/${currentUser.id}`)
        .then(response => {
          if (response?.data?.data) {
            // gon.current_user = response.data.data
            dispatch(currentUserUpdate(response.data.data))
          }
          dispatch(isCurrentUserLoading(false))
          return response
        })
        .catch(error => {
          dispatch(isCurrentUserLoading(false))
          return error
        })
    }
    //Set UTM cookies for all sessions
    setUtmCookie()
    setMounted(true)

    //if it is a sale-event or coupon or deal page check that the offer is cba exclusive and if it is, then check that the wtid is valid, else, redirect to homepage
    //This could be cleaned up I am sure <- moved into its own function?
    //Commented out 09-08-2022 6.10pm AEST due to CBA not including wtid in their last email
    //This should be re-enabled prior to next send
    /* if (['/sale-event/', '/voucher/', '/coupon/'].some(type => path.includes(type))) {
      const resourceSlug = path?.substr(path?.lastIndexOf('/') + 1)
      const item = getItem(resourceSlug)
      item.then(itemData => {
        if (itemData) {
          // Check item is cba exclusive
          CheckCbaExclusiveItem(itemData, wtid, currentUser, setCookie, wtidValid)
        } else {
          window.location = '/'
        }
      })
    } */
  }, [])

  useEffect(() => {
    cookies?.sp && isUserLoggedIn && dispatch(userAffinityRequestStart({ user_id: currentUser?.id, network_userid: cookies?.sp }))

    if (!Gleap.getInstance()?.initialized) {
      Gleap.initialize(GLEAP_KEY)
    }
  }, [isUserLoggedIn])


  useEffect(() => {
    if (nidGenerated) {
      let lbnId = localStorage.getItem('lbnid')

      if (lbnId == 'undefined') {
        let lbwebnid = localStorage.getItem('lbwebnid')
        if(!lbwebnid) {
          lbwebnid = crypto.randomUUID()
          localStorage.setItem('lbwebnid', lbwebnid)
        }
        localStorage.setItem('lbnid', lbwebnid)
        lbnId = lbwebnid
      }

      Gleap.clearIdentity()
      if (currentUser?.id) {
        Gleap.identify(`nid-${lbnId}_${currentUser.id}`, {
          name: currentUser.username,
          email: currentUser.attributes?.email
        })
      } else {
        Gleap.identify(`nid-${lbnId}`, {})
      }
      Gleap.showFeedbackButton(true)
    }
  }, [nidGenerated, isUserLoggedIn])

  // useEffect(() => {
  //   if (allExperiments?.length) {
  //     allExperiments.forEach(experiment => {
  //       const name = experiment.name.replaceAll(' ', '_')
  //       if (experiment.active && experiment.variants.length && activeExperiments.includes(experiment.name)) {
  //         const variantsNames = _.map(experiment.variants, 'name')
  //         const variantsWeights = _.map(experiment.variants, 'weight').map(li => Number(li))
  //         calculateActiveVariant({ name: name, id: experiment.id, version: experiment.version, variantsNames, variantsWeights, variants: experiment.variants })
  //       } else {
  //         const key = `PUSHTELL-${name}`
  //         removedExperimentSession(key)
  //       }
  //     })
  //   }
  // }, [allExperiments])

  // const removedExperimentSession = key => {
  //   const isExperimentActive = window.localStorage.getItem(key)
  //   isExperimentActive && window.localStorage.removeItem(key)
  // }

  useEffect(() => {
    if (bodyRef) {
      if (showUserMenu || showCatMenu) {
        disableBodyScroll(bodyRef)
      } else {
        enableBodyScroll(bodyRef)
      }
    }
    return clearAllBodyScrollLocks()
  }, [showUserMenu, showCatMenu, bodyRef])

  useEffect(() => {
    // To trigger personalise prompt via email url
    const url = window.location.search
    const queryParamObj = new URLSearchParams(url)
    const value = queryParamObj.get('preferences')
    const scrollId = queryParamObj.get('scroll')
    const welcomeValue = JSON.parse(queryParamObj.get('welcome'))
    value && localStorage.setItem('preferences', value)
    const isPreference = localStorage.getItem('preferences')
    const token = localStorage.getItem('auth_token')
    setModalShow(true)
    if (queryParamObj.get('fromExtension') && queryParamObj.get('doLogout')) {
      doLogout()
    }

    if (queryParamObj.has('fromExtension') && !queryParamObj.has('doLogout')) {
      sessionStorage.setItem('fromExtension', true)
    }
    if (isPreference && token) {
      sessionStorage.setItem('isNarratView', true)
      dispatch(setModalClose(true))
      history.push('/')
    }
    else if (value && !token) {
      sessionStorage.setItem('isNarratView', true)
      history.push('/login')
    }
     else {
      sessionStorage.setItem('isNarratView', true)
    }
    window.extentionEvents = {
      storeLinkClick: storeLinkClick,
      beActiveClickEvents: beActiveClickEvents
    }
    // redirect to extension page logic
    // setTimeout(()=>{
    //   if (!isExtensionRedirect && isHomePageRedirect) {
    //     window.snowplow('trackStructEvent', 'ux', 'view', 'homepage-redirect', null, null, [])
    //     localStorage.setItem('extensionRedirect', true)
    //     history.push('/extension'+window.location.search)
    //   }
    //  setModalShow(false)
    // },3500)
  }, [])

  // handle pushing event into dataLayer for each page
  useEffect(() => {
    // only push track-pageview for general page, if it is uniquely specified page (deal details page, saleEvent details page, coupon details page, etc.), specific event will be pushed into dataLayer instead of track-pageview event.
    if (!location.pathname.match(/\/shop\/\b(deal|voucher|coupon|sale-event)\//g)) {
      $(document).ready(() => {
        window.snowplow('trackPageView')
      })
    }

    // Remove top padding in webview.
    document.body.style.paddingTop = hideNavBar ? '0px' : (window.innerWidth >= 1200 ? '88px' : '56px');
  }, [location.pathname])

  let editUsername = (
    <>
      <ScrollToTop />
      {/* <MainNavbar isHomepage={homePage} hideTopNavbar={hideNavBar} /> */}
      <CloseNavBar />
      <Main />
      {/* <div className={`${homePage ? 'isHomePage' : 'content'} ${window.ReactNativeWebView ? 'non-footer-view' : ''}`} ref={bodyRef}>
        {((currentUser && currentUser.type) || userId?.show) && <FavouritesModal currentUser={currentUser} />}
        {
          isHomePageRedirect &&
            <Modal show={modalShow && !isExtensionRedirect} className='lbredirect-modal'>
              <div className='lb-redirect'>
                <img className='img-fluid' src={LbRedirect} />
              </div>
            </Modal>
        }

        {userId?.isPersonalise ? <PersonalisedPage /> : <Main />}
        {!location.pathname.includes('newsletters') && !userId?.isPersonalise && <BackToTopButton />}
        {isMobile && <MobileActionBar user={currentUser && currentUser.type ? currentUser : null} />}
      </div>
      {!window.ReactNativeWebView && <Footer currentUser={currentUser} />} */}
      <Notifications />
      <LastLocationCookie />
    </>
  )

  //Set UTM Cookie
  const setUtmCookie = () => {
    const cookieAge = new Date()
    const options = {
      path: '/',
      expires: cookieAge,
      maxAge: 60 * 60 * 24,
      secure: true,
      SameSite: 'Lax'
    }

    // remove wtid from query Object
    const queryObj = location?.query || {}
    const keys = Object.keys(queryObj)
    const wtidKey = keys.filter(value => value.toLowerCase() === 'wtid')
    const utmParam = _.omit(queryObj, wtidKey)
    if (utmParam) {
      _.forEach(utmParam, (value, key) => {
        key && setCookie(key, value, options)
      })
    }
  }

  // Handle CBA user

  // const checkValidWtid = wtid => {
  //   axios.get(`${apiBaseUrl}/wtid/${wtid}/valid`).then(response => {
  //     return response.data
  //   })
  // }


  // const checkExclusivePage = wtid => {
  //   axios.get(`${apiBaseUrl}/wtid/${wtid}/valid`).then(response => {
  //     if (response.data) {
  //       // set utm_parameter in cookie for registration usages
  //       const cookieAge = new Date()
  //       cookieAge.setDate(cookieAge.getDate() + 1)

  //       // remove wtid from query Object

  //       // Check request is from CBA and set Cookie
  //       if (['/sale-event/', '/voucher/', '/coupon/'].some(type => path.includes(type))) {
  //         const resourceSlug = path?.substr(path?.lastIndexOf('/') + 1)
  //         const item = getItem(resourceSlug)
  //         item.then(itemData => {
  //           if (itemData) {
  //             // Check item is cba exclusive
  //             CheckCbaExclusiveItem(itemData, wtid, currentUser, setCookie)
  //           } else {
  //             window.location = '/'
  //           }
  //         })
  //       } else {
  //         // Set cookie in case of direct redirect from CBA
  //         SetCbaCookie(wtid, setCookie)
  //       }

  //       /* const queryParamObj = new URLSearchParams(utmParam)
  //       const queryString = queryParamObj.toString()
  //       if (queryString.length) {
  //         window.location = `${location.pathname}?${queryString}`
  //       } else {
  //         window.location = `${location.pathname}`
  //       } */
  //     } else {
  //       window.location = '/'
  //     }
  //   })
  // }

  // GET Item details to check item is CBA exclusive or not
  // const getItem = async (resourceSlug) => {
  //   if (path.includes('/sale-event/')) {
  //     const response = await axios.get(`${apiBaseUrl}/sale_events/${resourceSlug}`, {})
  //     return response?.data?.data || null
  //   }
  //   if (path.includes('/voucher/') || path.includes('/coupon/')) {
  //     const response = await axios.get(`${apiBaseUrl}/coupons/${resourceSlug}`, {})
  //     return response?.data?.data || null
  //   }
  //   return null
  // }

  if (
    // location.pathname.startsWith('/goto') ||
    // location.pathname.startsWith('/cba') ||
    location.pathname.startsWith('/mobile/ext')) {
    return (
      <>
        <Main />
      </>
    )
  }

  // if (currentUser) {
  //   if (currentUser.attributes?.username === null) {
  //     editUsername = (
  //       <>
  //         <BasicNavbar />
  //         <Main />
  //         <Redirect to='/edit_username' />
  //       </>
  //     )
  //   }
  // }

  // if (location.pathname === '/welcome') {
  //   return <WelcomePage />
  // }

  // if (!currentUser) {
  //   if (
  //     location.pathname.startsWith('/users/password/new') ||
  //     location.pathname.startsWith('/users/password/edit')
  //   ) {
  //     editUsername = (
  //       <>
  //         <BasicNavbar />
  //         <Main />
  //         <Redirect to={location} />
  //       </>
  //     )
  //   }
  // }

  return (
    <>
      {editUsername}
    </>
  )
}

export default startBoardApp
